<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة دواء</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="productName" label="اسم الدواء"></v-text-field>

              <v-text-field rounded outlined v-model="scientificName" label="الاسم العلمي"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="productDose" label="الجرعة"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="buyPrice" label="سعر الشراء"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="sellPrice" label="سعر البيع"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="quantity" label="الكمية"></v-text-field>

              <v-text-field rounded outlined type="date" v-model="expireDate" label="تاريخ النفاذ"></v-text-field>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="productDescription" name="input-7-4" label="وصف الدواء"></v-textarea>

              <v-file-input rounded multiple label="صورة الدواء" outlined v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة دواء</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    show: false,
    overlay: false,
    productName: "",
    productDescription: "",
    productImage: "",
    productDose: "",
    sellPrice: "",
    buyPrice: "",
    quantity: "",
    scientificName: "",
    expireDate: "",
    files: [],
  }),
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("attachment", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadProductImage`, formData).then((res) => {
          self.overlay = false;
          self.productImage = res.data;
          console.log(res);
        });
      }
    },
    saveData() {
      let self = this;

      let data2 = {
        productName: self.productName,
        productDescription: self.productDescription,
        productImage: self.productImage.imagePath,
        productDose: self.productDose,
        buyPrice: self.buyPrice,
        sellPrice: self.sellPrice,
        scientificName: self.scientificName,
        expireDate: self.expireDate,
        quantity: self.quantity,
      };

      console.log(data2);

      if (self.productName && self.productDescription) {
        let data = {
          productName: self.productName,
          productDescription: self.productDescription,
          productImage: self.productImage.imagePath,
          productDose: self.productDose,
          buyPrice: self.buyPrice,
          sellPrice: self.sellPrice,
          scientificName: self.scientificName,
          expireDate: self.expireDate,
          quantity: self.quantity,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addProduct`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
