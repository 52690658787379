<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        القبض اليومي
        <v-spacer></v-spacer>
        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddIncome @reloadTask="initialData()" :doctors="doctors" :patients="patients" :doctorMaterials="doctorMaterials" />
        <v-btn class="mx-2" fab small outlined color="dark" @click="printReport">
          <v-icon dark> mdi-printer </v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <h4 style="padding: 0 3px">
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="printVoucher(item)">
            <v-icon color="primary"> mdi-printer </v-icon>
          </v-btn>

          <DeleteIncome @reloadTask="initialData" :dataToDelete="item" v-if="$store.state.userData.roleId == 1" style="display: inline-block" />
        </template>
      </v-data-table>
      <div style="padding: 20px">
        <h3>
          القبض اليومي :
          {{
            todyReceipt().toLocaleString("en-US", {
              style: "currency",
              currency: "IQD",
            })
          }}
        </h3>
      </div>
    </v-card>
  </div>
</template>

<script>
import AddIncome from "./income/AddIncome.vue";
import DeleteIncome from "./income/DeleteIncome.vue";
// import n2wordsAR from "n2words/lib/i18n/AR.mjs";
import n2wordsAR from "n2words";

export default {
  components: {
    AddIncome,
    DeleteIncome,
  },
  data: () => ({
    overlay: false,
    search: "",
    moneyIncomes: [],
    doctors: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "رقم الوصل", value: "idMoneyIncome" },
      { text: "اسم المراجع", value: "patientName" },
      { text: "اسم المادة", value: "materialName" },
      { text: "اسم الطبيب", value: "userName" },
      { text: "المبلغ", value: "amount" },
      { text: "الملاحظات", value: "notes" },
      { text: "المحاسب", value: "createdName" },
      { text: "الاجراءات", value: "actions" },
    ],
    patients: [],
    doctorMaterials: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    todyReceipt() {
      let self = this;
      let date = new Date();
      let today = `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
      let filterMoney = self.moneyIncomes.filter((income) => income.createdAtFormat == today);

      let sumAmount = filterMoney.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

      if (sumAmount) {
        return sumAmount;
      } else {
        return 0;
      }
    },
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/moneyIncomes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/allDoctors`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/patients`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/doctorMaterials`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((moneyIncomes, doctors, patients, doctorMaterials) => {
            self.moneyIncomes = moneyIncomes.data;
            self.doctors = doctors.data;
            self.patients = patients.data;
            self.doctorMaterials = doctorMaterials.data;
            console.log(moneyIncomes.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    printVoucher(voucher) {
      let self = this;

      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("./../../assets/logo.png");
      let backgroundImage = require("./../../assets/receiptImage.jpg");

      console.log(backgroundImage);
      console.log(image);

      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });

      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="ar">
        ${stylesHtml}
          <style>
          body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
           

          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
               
                page-break-before: avoid;
                page-break-before: avoid;
                
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background-image:url('${backgroundImage}') !important;
              background-size:cover !important;
            }
            .print-padding {
              padding: 5px !important;
            } 
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 0px;height:99vh;">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                
                  <div data-v-4a284cfa="" class="col col-6">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 40px;text-align:left;padding:0 20px">
                      <img src="${image}"  width="50px"/>
                    </div>
                  </div>

                  <div data-v-4a284cfa="" class="col col-6">
                    <h3 data-v-4a284cfa="" style="padding-top:30px;text-align:right;padding-right:20px">
                      مركز التراث الطبي / الحسابات
                    </h3>
                  </div>
               
                </div>
                <div data-v-4a284cfa="">
                 
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div style="height:30px"></div>
                  <div data-v-4a284cfa="" class="row">
                    
                    <div data-v-4a284cfa="" class="col col-4"">
                        <h3 style="display:inline-block">
                        ${voucher.createdAtFormat}
                      </h3>
                      <h3 style="display:inline-block">
                        : التاريخ  
                      </h3>
                    </div>

                    <div data-v-4a284cfa="" class="col col-4"></div>

                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 style="display:inline-block;">
                        ${voucher.patientName}
                      </h3>
                      <h3 style="display:inline-block;">
                        : اسم المراجع  
                      </h3>
                    </div>

                  </div>



                  <div data-v-4a284cfa="" class="row">
                    
                    <div data-v-4a284cfa="" class="col col-4"">
                        <h3 style="display:inline-block">
                        ${voucher.createdName}
                      </h3>
                      <h3 style="display:inline-block">
                        : اسم المستخدم  
                      </h3>
                    </div>

                    <div data-v-4a284cfa="" class="col col-4"></div>

                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 style="display:inline-block;">
                        ${voucher.userName}
                      </h3>
                      <h3 style="display:inline-block;">
                        : اسم الطبيب  
                      </h3>
                    </div>

                  </div>


                  <div data-v-4a284cfa="" class="row">
                    
                    <div data-v-4a284cfa="" class="col col-4"">
                      <h3 style="display:inline-block;">
                        ${voucher.incomeTime}
                      </h3>
                      <h3 style="display:inline-block;">
                        : وقت الخدمة  
                      </h3>
                    </div>

                    <div data-v-4a284cfa="" class="col col-4"></div>

                    <div data-v-4a284cfa="" class="col col-4"></div>

                  </div>

                  <table width="100%" style="padding:30px">

                    <tr style="border:1px solid #000">
                      <th  style="border:1px solid #000">
                        <h3>الملاحظات</h3>
                        
                      </th>
                      <th  style="border:1px solid #000">
                        <h3>الخصم</h3>
                      </th>
                      <th  style="border:1px solid #000">
                        <h3>السعر</h3>
                        </th>
                      <th  style="border:1px solid #000">
                      <h3>نوع الخدمة</h3>
                      </th>
                    </tr>
                    <tr style="border:1px solid #000">
                      <td  style="border:1px solid #000">
                        <h3> ${voucher.notes}</h3>
                        
                       </td>
                      <td  style="border:1px solid #000"><h3>0</h3></td>
                      <td  style="border:1px solid #000">
                        <h3>${voucher.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })}</h3>
                      </td>
                      <td  style="border:1px solid #000"><h3>${voucher.materialName}</h3></td>
                    
                    </tr>
                    
                  </table>


                  <div data-v-4a284cfa="" class="row">
                    
                    <div data-v-4a284cfa="" class="col col-4"">
                        <h3 style="display:inline-block">
                       0
                      </h3>
                      <h3 style="display:inline-block">
                        : الخصم  
                      </h3>
                    </div>

                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 style="display:inline-block;">
                        ${voucher.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })}
                      </h3>
                      <h3 style="display:inline-block;">
                        : المدفوع  
                      </h3>  
                    </div>

                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 style="display:inline-block;">
                        ${voucher.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })}
                      </h3>
                      <h3 style="display:inline-block;">
                        : الاجمالي  
                      </h3>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </body>
        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },

    printReport() {
      let self = this;
      let date = new Date();
      let today = `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
      let items = self.moneyIncomes.filter((income) => income.createdAtFormat == today);
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          @page { margin: 0.5cm !important; }
          
          .pagebreak {
            clear: both;page-break-after: always;
          }
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
            table {
            border-collapse: collapse;
            width: 100%;
            }

            th, td {
            text-align: left;
            padding: 8px;
            }

            tr:nth-child(even) {
            background-color: #D6EEEE;
            }
        </style>
        <body>
            <table border='0' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>اسم المراجع</th>
                <th>اسم المادة</th>
                <th>اسم الطبيب</th>
                <th>المبلغ</th>
                <th colspan="4">الملاحظات</th>
                <th>المحاسب</th>
              </tr>
              
               ${self.rowTable(items)}
          </table>
           <h3>المجموع : ${items
             .reduce((n, { amount }) => n + amount, 0)
             .toLocaleString("en-US", {
               style: "currency",
               currency: "IQD",
             })}</h3>
        </body>`);

      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 1000);
    },
    printTableData() {
      let self = this;
      let tableData = "";

      for (let i = 0; i < items.length; i++) {
        tableData += `
              ${self.rowTable(items[i])}
            ${i == items.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${j + 1}</td>
                <td>${arrayOfData[j].idMoneyIncome}</td>
                <td>${arrayOfData[j].patientName}</td>
                <td>${arrayOfData[j].materialName}</td>
                <td>${arrayOfData[j].userName}</td>
                
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
                <td  colspan="4"> ${arrayOfData[j].notes}</td>
                <td>${arrayOfData[j].createdName}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
  },
  computed: {
    ItemsFilter() {
      let date = new Date();
      let today = `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
      if (this.search) {
        return this.moneyIncomes.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.patientName.toLowerCase().includes(v));
        });
      } else {
        return this.moneyIncomes.filter((income) => income.createdAtFormat == today);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
